import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { admin_api } from "../../../API/services/admin";
import { workflow_api } from "../../../API/services/workflow";
import { current } from '@reduxjs/toolkit';

const initialState = {
    actionPlanTypes:[],
    actionPlan:[],
    actionPlanDetails:[],
    actionPlanTaskDetails:[],
    isLoading:false,
    error:null,
    success:false,
    isActionPlanTypeLoading:false,
    selectedActionPlanType: undefined,
    selectedActionPlan: undefined,
    isActionPlanLoading:false,
    selectedactionPlanDetails: undefined,
    isActionPlanDetailsLoading:false,
    selectedactionPlanTaskDetails: undefined,
    isactionPlanTaskDetailsLoading:false,
    selectedAction: '',
    isEditSubmitted: false,
    isEditActionPlanType:false,
    isEditActionPlan:false,
    isEditactionPlanDetails:false,
    isEditactionPlanTaskDetails:false,
    ShowActionPlanPopUp:false,
    ShowActionPlanId:null
};

export const fetchAllActionPlanTypeList = createAsyncThunk(
    'categories/get-all-action-plan-types',
    async (_,{rejectWithValue} ) => {
        try{
            const token = await getTokenFromSession();
            const response = await axios.get(admin_api.getAllActionPlanTypes.url,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        }catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchAllActionPlanList = createAsyncThunk(
    'wf/get-all-jobs',
    async (actionPlan,{rejectWithValue} ) => {
      try{
        const token = await getTokenFromSession();
        const response = await axios.post(admin_api.getAllActionPlanWithIncident.url,actionPlan,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        return response.data;
        
      }catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const fetchAllActionPlanDetails = createAsyncThunk(
    'get/getWorkflowDetailById',
    async (job,{rejectWithValue} ) => {
      try{
        const token = await getTokenFromSession();
        const response = await axios.post(workflow_api.getAllWorkflowDetail.url,job,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        return response.data;
        
      }catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const fetchAllActionPlanTaskDetails = createAsyncThunk(
  'wf/get-tasks-list',
  async (job,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.get(workflow_api.gettaskslist.url,
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: job
      });
      return response.data;
      
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addActionPlanTypeSlice = createSlice({
    name: 'ActionPlan',
    initialState,
    reducers: {
      updateActionPlanPopUp(state, action) {
        state.ShowActionPlanPopUp = action.payload.status;
        state.ShowActionPlanId = action.payload.APId;
        state.isactionPlanTaskDetailsLoading = true;
      },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllActionPlanTypeList.pending, (state) => {
                state.isActionPlanTypeLoading = true;
            })
            .addCase(fetchAllActionPlanTypeList.fulfilled, (state, action) => {
                state.actionPlanTypes = action.payload;
                state.isActionPlanTypeLoading = false;
            })   
            .addCase(fetchAllActionPlanTypeList.rejected, (state, action) => {
                state.isActionPlanTypeLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchAllActionPlanList.pending, (state) => {
                state.isActionPlanLoading = true;
            })
            .addCase(fetchAllActionPlanList.fulfilled, (state, action) => {
                state.actionPlan = action.payload;
                state.isActionPlanLoading = false;
            })   
            .addCase(fetchAllActionPlanList.rejected, (state, action) => {
                state.isActionPlanLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchAllActionPlanDetails.pending, (state) => {
                state.isActionPlanDetailsLoading = true;
            })
            .addCase(fetchAllActionPlanDetails.fulfilled, (state, action) => {
                state.actionPlanDetails = action.payload;
                state.isActionPlanDetailsLoading = false;
            })   
            .addCase(fetchAllActionPlanDetails.rejected, (state, action) => {
                state.isActionPlanDetailsLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchAllActionPlanTaskDetails.pending, (state) => {
              state.isactionPlanTaskDetailsLoading = true;
            })
            .addCase(fetchAllActionPlanTaskDetails.fulfilled, (state, action) => {
              state.actionPlanTaskDetails = action.payload;
              state.isactionPlanTaskDetailsLoading = false;
            })   
            .addCase(fetchAllActionPlanTaskDetails.rejected, (state, action) => {
              state.isactionPlanTaskDetailsLoading = false;
              state.error = action.payload;
            })
    }
})

export default addActionPlanTypeSlice.reducer;
export const { updateActionPlanPopUp } = addActionPlanTypeSlice.actions