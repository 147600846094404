import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    onlineUsers: [],
    currentUser: {},
    userProfile: {},
    calleeDecList: []
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser(state, action) {   
            return { ...state, ...action.payload };
        },
        setOnlineUsers(state, action) {            
            state.onlineUsers = action.payload.online_users;
        },
        setUserProfile(state, action) {
            state.userProfile = action.payload.user_profile;
        },
        setCalleeList(state, action) {
            state.calleeList = action.payload.calleeList;
        },
        setIsCurrentUserOnCall(state, action) {
            state.currentUser.isOnCall = action.payload.isOnCall
        },
        setCalleeDecList(state, action) {
            state.calleeDecList = action.payload.calleeDecList
        }
    }
});

export default userSlice.reducer;
export const { 
    addUser,
    setOnlineUsers,
    setUserProfile,
    setCalleeList,
    setIsCurrentUserOnCall,
    setCalleeDecList
} = userSlice.actions;
