import { getTokenFromSession } from "../../helpers";

let backend_domain = window._env_.REACT_APP_API_URL;
let api_version = window._env_.REACT_APP_API_VERSION;

const token = await getTokenFromSession();

//work flow api's
export const asset = {
  create: {
    method: "POST",
    url: `${backend_domain}${api_version}/assets/create-asset`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  },
  getAllAssets: {
    method: "GET",
    url: `${backend_domain}${api_version}/assets/get-all-assets`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
  edit: {
    method: "PUT",
    url: `${backend_domain}${api_version}/assets/edit/asset`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
  delete: {
    method: "DELETE",
    url: `${backend_domain}${api_version}/assets/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
};
