import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { workflow_api } from "../../../API/services/workflow";

const initialState = {
  templateFormData: {
    job_name: "",
    job_description: "",
    tasks: [],
  },

  templateSummary: null,

  allTemplates: null,
  allJobs: null,
  selectedWorkflowId: localStorage.getItem("selectedJob") || null,
  loading: false,
  error: null,
  loadingEdit: false,
  errorEdit: null,
  message: null,
  loadingCreate: false,
  errorCreate: null,
};

//create template
export const createTemplateAPI = createAsyncThunk("create/template", async (payload, { rejectWithValue }) => {
  try {
    const { index, job_id, ...updatedPayload } = payload;
    console.log("payload", updatedPayload);

    const finalPayload = {
      ...updatedPayload,
      source_type: "",
    };
    //console.log("finalPayload__33",finalPayload)

    const token = await getTokenFromSession();
    const response = await axios.post(`${workflow_api.createWorkflow.url}`, finalPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//create jobs
export const createJobAPI = createAsyncThunk("create/job", async (payload, { rejectWithValue }) => {
  try {
    const { index, task_category_type, ...updatedPayload } = payload;
    console.log("payload", updatedPayload);

    const token = await getTokenFromSession();
    const response = await axios.post(`${workflow_api.createWorkflow.url}`, updatedPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//get all workflow data by type
export const getWorkflowByType = createAsyncThunk("get/getWorkflowByType", async (payload, { rejectWithValue }) => {
  try {    
    const token = await getTokenFromSession();

    const response = await axios.post(
      `${workflow_api.getAllWorkflow.url}`,
      {
        job_type: [payload],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    // Handle errors and return the error response
    return rejectWithValue(error.response?.data || error.message);
  }
});

//clone template
export const cloneTemplateAPI = createAsyncThunk("clone/template", async (payload, { rejectWithValue }) => {
  console.log("payload___104", payload);
  try {
    const {
      index,
      asset_pk,
      job_id: id,
      assigned_to,
      assignee_first_name,
      assignee_last_name,
      c_id,
      createdAt,
      created_by,
      creater_first_name,
      creater_last_name,
      end_time,
      start_time,
      ...updatedPayload
    } = payload;

    const finalPayload = {
      ...updatedPayload,
      source_type: "template",
      id,
    };

    console.log("clone_payload", finalPayload);

    const token = await getTokenFromSession();
    const response = await axios.post(`${workflow_api.createWorkflow.url}`, finalPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//clone Job
export const cloneJobAPI = createAsyncThunk("clone/job", async (payload, { rejectWithValue }) => {
  try {
    const {
      index,
      asset_pk,
      job_id: id,
      // assigned_to,
      assignee_first_name,
      assignee_last_name,
      c_id,
      createdAt,
      created_by,
      creater_first_name,
      creater_last_name,
      // end_time,
      // start_time,
      ...updatedPayload
    } = payload;

    const finalPayload = {
      ...updatedPayload,
      id,
    };

    console.log("clone_payload", finalPayload);

    const token = await getTokenFromSession();
    const response = await axios.post(`${workflow_api.createWorkflow.url}`, finalPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//get all workflow data by id
export const getWorkflowDetailById = createAsyncThunk("get/getWorkflowDetailById", async (id, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();

    const response = await axios.post(
      `${workflow_api.getAllWorkflowDetail.url}`,
      {
        job_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    // Handle errors and return the error response
    return rejectWithValue(error.response?.data || error.message);
  }
});

export const getWorkflowSummary = createAsyncThunk("get/getWorkflowSummary", async (id, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();

    const response = await axios.post(
      `${workflow_api.getAllWorkflowSummary.url}`,
      {
        job_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    // Handle errors and return the error response
    return rejectWithValue(error.response?.data || error.message);
  }
});


//edit template
export const updateTemplateAPI = createAsyncThunk("edit/template", async (payload, { rejectWithValue }) => {
  // console.log("payload for updatetemplate", payload);
  try {
    const {
      index,
      job_id: id,
      asset_pk,
      assigned_to,
      assignee_first_name,
      assignee_last_name,
      c_id,
      createdAt,
      created_by,
      creater_first_name,
      creater_last_name,
      end_time,
      start_time,
      source_type, //TZ-2060
      ...updatedPayload
    } = payload;

    const finalPayload = {
      ...updatedPayload,
      id,
    };

    const token = await getTokenFromSession();
    const response = await axios.put(`${workflow_api.editTemplate.url}`, finalPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("----->>>>",response.data)
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//edit job
export const updateJobAPI = createAsyncThunk("edit/job", async (payload, { rejectWithValue }) => {
  try {
    const {
      index,
      asset_pk,
      job_id: id,
      //assigned_to,
      assignee_first_name,
      assignee_last_name,
      c_id,
      createdAt,
      created_by,
      creater_first_name,
      creater_last_name,
      //end_time,
      //start_time,
      ...updatedPayload
    } = payload;

    const finalPayload = {
      ...updatedPayload,
      id,
    };

    const token = await getTokenFromSession();
    const response = await axios.put(`${workflow_api.editTemplate.url}`, finalPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//job from template
export const instantiateTemplateToJob = createAsyncThunk(
  "instantiate/template-to-job",
  async (payload, { rejectWithValue }) => {
    try {
      const {
        index,
        asset_pk,
        job_id: id,
        //assigned_to,
        assignee_first_name,
        assignee_last_name,
        c_id,
        createdAt,
        created_by,
        creater_first_name,
        creater_last_name,
        //end_time,
        //start_time,
        ...updatedPayload
      } = payload;

      const finalPayload = {
        ...updatedPayload,
        id,
      };
      console.log("instantiateTemplateToJob", finalPayload);

      const token = await getTokenFromSession();
      const response = await axios.post(`${workflow_api.createWorkflow.url}`, finalPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete workflowByID
export const deletWorkflowByID = createAsyncThunk("delete/workflow", async (job_id, { rejectWithValue }) => {
  try {
    console.log("deletWorkflowByID", job_id);
    const token = await getTokenFromSession();
    const response = await axios.delete(`${workflow_api.deleteWorkflow.url}/${job_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteTaskAPI = createAsyncThunk("edit/deleteTask", async (payload, { rejectWithValue }) => {
  try {
    const {
      task_id,
      job_id: id,
      assignee_first_name,
      assignee_last_name,
      c_id,
      createdAt,
      created_by,
      creater_first_name,
      creater_last_name,
      is_delete,
      ...jobDetails
    } = payload;

    const updatedTasks = jobDetails.tasks.map((task) =>
      task.task_id === task_id ? { ...task, is_delete: true } : task
    );

    const finalPayload = {
      ...jobDetails,
      tasks: updatedTasks,
      id,
    };

    const token = await getTokenFromSession();

    const response = await axios.put(`${workflow_api.editTemplate.url}`, finalPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteTemplateTaskAPI = createAsyncThunk("edit/deleteTask", async (payload, { rejectWithValue }) => {
  try {
    const {
      index,
      job_id: id,
      is_delete,
      task_id,
      asset_pk,
      assigned_to,
      assignee_first_name,
      assignee_last_name,
      c_id,
      createdAt,
      created_by,
      creater_first_name,
      creater_last_name,
      end_time,
      start_time,
      ...jobDetails
    } = payload;

    const updatedTasks = jobDetails.tasks.map((task) =>
      task.task_id === task_id ? { ...task, is_delete: true } : task
    );

    const finalPayload = {
      ...jobDetails,
      tasks: updatedTasks,
      id,
    };

    const token = await getTokenFromSession();

    const response = await axios.put(`${workflow_api.editTemplate.url}`, finalPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteReferenceAPI = createAsyncThunk("edit/deleteReference", async (payload, { rejectWithValue }) => {
  try {
    const {
      attachment_id,
      job_id: id,
      assignee_first_name,
      assignee_last_name,
      c_id,
      createdAt,
      created_by,
      creater_first_name,
      creater_last_name,
      is_delete,
      ...jobDetails
    } = payload;

    // Update the references array: find the reference by attachment_id and mark it as deleted
    const updatedTasks = jobDetails.tasks.map((task) => {
      const updatedReferences = task.references.map((reference) =>
        reference.attachment_id === attachment_id ? { ...reference, is_delete: true } : reference
      );

      return { ...task, references: updatedReferences };
    });

    const finalPayload = {
      ...jobDetails,
      tasks: updatedTasks,
      id,
    };

    // Get the token from session (assuming getTokenFromSession is already defined)
    const token = await getTokenFromSession();

    const response = await axios.put(`${workflow_api.editTemplate.url}`, finalPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//template from kc
export const createTemplateForKcAPI = createAsyncThunk(
  "create/kc-to-template",
  async (payload, { rejectWithValue }) => {
    try {
      const {
        kc_id,
        description,
        kc_status,
        job_id: id,
        index,
        asset_pk,
        assigned_to,
        assignee_first_name,
        assignee_last_name,
        c_id,
        createdAt,
        created_by,
        creater_first_name,
        creater_last_name,
        // end_time,
        // start_time,
        ...updatedPayload
      } = payload;

      const finalPayload = {
        ...updatedPayload,
        id,
      };

      console.log("finalPayload", finalPayload);

      const token = await getTokenFromSession();
      const response = await axios.post(`${workflow_api.createWorkflow.url}`, finalPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getTemplateSlice = createSlice({
  name: "allworkflow",
  initialState,
  reducers: {
    selectedWorkflowId(state, action) {
      state.selectedWorkflowId = action.payload.id;
      localStorage.setItem("selectedJob", action.payload.id);
    },

    createTemplate(state, action) {
      const stateData = state.templateFormData ? JSON.parse(JSON.stringify(state.templateFormData)) : {}; // Fallback to an empty object if state.templateFormData is undefined
      
      state.templateFormData = {
        ...stateData,
        ...action.payload,
      };
    },
   
  },

  extraReducers: (builder) => {
    builder
      .addCase(createTemplateAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTemplateAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(createTemplateAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //create job
      .addCase(createJobAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createJobAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(createJobAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //all templates
      .addCase(getWorkflowByType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkflowByType.fulfilled, (state, action) => {
        state.loading = false;
        state.allTemplates = action.payload.data;
      })
      .addCase(getWorkflowByType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //workflow details
      .addCase(getWorkflowDetailById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkflowDetailById.fulfilled, (state, action) => {
        state.loading = false;
        state.templateFormData = action.payload;
      })
      .addCase(getWorkflowDetailById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //workflow summary
      .addCase(getWorkflowSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkflowSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.templateSummary = action.payload;
      })
      .addCase(getWorkflowSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //delete workflow
      .addCase(deletWorkflowByID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletWorkflowByID.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deletWorkflowByID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Actions
export const { selectedWorkflowId, createTemplate, resetState,selectJob } = getTemplateSlice.actions;
export default getTemplateSlice.reducer;
